import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import Exchange from '../components/exchange/exchange';
import PresaleStats from '../components/exchange/presale-stats';
import ExchangeHistory from '../components/exchange/history';
import NetworkSetter from '../components/network/networksetter';
import IndexedDBProvider from 'use-indexeddb/dist/use-indexeddb.esm';
import { idbConfig } from '../web3/orders';

const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);
    return { isClient, key };
};

function Otc() {
    const { isClient } = useIsClient();

    if (!isClient) { return null; }

    return (
        <div className="py-md-2">
            <NetworkSetter chainId={56}></NetworkSetter>
            <IndexedDBProvider config={idbConfig} loading="Loading..." fallback="Unsupported">
                <Row className="mb-2 justify-content-center flex-wrap-reverse gap-3 gap-md-0 ">
                    <Col lg={8}>
                        <Row className="gap-3 flex-wrap-reverse flex-md-wrap">
                            <Col md={12}>
                                <PresaleStats ></PresaleStats>

                            </Col>
                            <Col md={12}>
                                <ExchangeHistory ></ExchangeHistory>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} style={{ height: '100%' }}>
                        <Exchange></Exchange>
                    </Col>
                </Row>
            </IndexedDBProvider>
        </div >
    );
}

export default Otc;
