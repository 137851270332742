import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import NetworkContext from '../../context/network-context';
import networkIcon from '../../images/chain-icon';
import { fToken } from '../../utils/formatNumber';
import { computeTradeDetails } from '../../web3/price-data';
import { getRouter } from "../../web3/router-utils";
import { addChain, networks, TARGET_NETWORK } from '../../web3/web3';
import AsyncItem from '../misc/AsyncItem';
import CloseButton from 'react-bootstrap/CloseButton';

function ExchangeConfirm({ 
    cancel,
    balance, action, baseValue, quote, base, price, swapper, isApproved, handleApprove, 
    handleUntrack, showTrack, track }) {
    const { pendingTransaction, isCorrectNetwork, connected } = useContext(NetworkContext);

    const [minAmount, setMinAmount] = useState(0);
    const [quoteValue, setQuoteValue] = useState(0);
    const [slippage, setSlippage] = useState(0.1);
    const [slippageAuto, setSlippageAuto] = useState(true);
    const [trade, setTrade] = useState();
    const [approved, setApproved] = useState();
    const [warning, setWarning] = useState(null);
    const [trackOrder, setTrackOrder] = useState(false);
    const [calculatingSlippage, setCalculatingSlippage] = useState(true);

    const calclateAutoSlippage = useCallback(async () => {
        if (!swapper || !base || !quote || baseValue === null || baseValue === undefined) {
            return
        }
        setCalculatingSlippage(true);
        const trade = await computeTradeDetails(baseValue, base, quote, swapper);
        setTrade(trade);
        const { FEE } = await getRouter(trade.path, swapper);
        setCalculatingSlippage(false);
        return Number(trade.slippage + FEE).toFixed(2)
    }, [baseValue, quote, base, swapper]);

    const checkValid = useCallback((slippage) => {
        if (slippage > 5) {
            setWarning('Warning! High slippage can lead to an extremely unfavorable swap price.');
            return;
        }
        if (slippage < 0.2) {
            setWarning('Warning! Transaction may fail.');
            return;
        }
        setWarning(null);
    }, []);

    const handleConfirm = useCallback(() => {
        action(trade.path, +minAmount.toFixed(8), trackOrder);
    }, [trade, minAmount, trackOrder])

    const updateSlippage = useCallback(async (slippage) => {
        if (slippage === 'auto') {
            slippage = await calclateAutoSlippage();
            setSlippageAuto(true);
        } else {
            setSlippageAuto(false);
        }

        if (slippage > 50) {
            slippage = 50;
            setWarning('Maximum slippage is 50.');
        }

        setSlippage(slippage);
        checkValid(slippage);
    }, [setSlippage, checkValid, calclateAutoSlippage]);


    useEffect(() => {
        updateSlippage('auto');
    }, [updateSlippage]);

    useEffect(() => {
        const baseFee = base?.sellFee || 0;
        const quoteFee = quote?.buyFee || 0;

        const tax = 0.01 + baseFee + quoteFee;
        const _quoteValue = baseValue * price;
        const _minAmount = _quoteValue - (_quoteValue * ((+slippage + tax) / 100));
        setMinAmount(_minAmount);
    }, [slippage, baseValue, quote, price, base]);

    useEffect(() => {
        setQuoteValue(baseValue * price);
    }, [price, baseValue]);

    useEffect(() => {
        setApproved(isApproved);
    }, [isApproved])

    useEffect(() => {
        setTrackOrder(track);
    }, [track])

    const ActionButton = () => {
        if (balance < baseValue) {
            return (<>
                {handleUntrack && <Button variant='link'
                    disabled={!connected || !isCorrectNetwork || !!pendingTransaction || slippage > 50 || !slippage}
                    onClick={handleUntrack} className="mb-2 w-100 clickable" >Untrack Order</Button>}
                <p className="m-0">You don't have sufficient balance to close this order.</p>
            </>)
        }

        if (!isCorrectNetwork && connected) {
            const networkName = networks[+TARGET_NETWORK].shortName;
            return (<Button
                disabled={!connected || !!pendingTransaction}
                onClick={() => addChain(+TARGET_NETWORK)} className="mb-2 d-flex justify-content-center align-items-center text-center w-100" >
                Switch to {networkName}
                <img style={{ width: '20px', height: '20px', marginLeft: '0.5rem' }}
                    src={networkIcon[networkName]} alt="icon" />
            </Button>);
        }

        if (approved) {
            return (<Button
                disabled={calculatingSlippage || !connected || !isCorrectNetwork || !!pendingTransaction || slippage > 50 || !slippage}
                onClick={handleConfirm} className="mb-2 w-100" >Confirm</Button>);
        } else {
            return (<Button disabled={!connected || !isCorrectNetwork || !!pendingTransaction}
                onClick={async () => {
                    await handleApprove(baseValue)
                    setApproved(true);
                }} className="mb-2 w-100" >Approve</Button>);
        }
    };


    return (
        <div className="staking-input-component">
            <Card>
                <Card.Body>
                    <Row style={{ marginBottom: '1rem' }} >
                        <Col md={12} className="d-flex justify-content-between">
                            <h3 className="text-white" style={{fontSize: 32}}>Swap</h3>
                            <CloseButton onClick={cancel} className="confirm-close" variant="white" ></CloseButton>
                        </Col>
                    </Row>
                    <Row className="exchange-confirm-summary" >
                        <Col md={12} className="mb-4 d-flex justify-content-between">
                            <div className="exchange-group">
                                <img
                                    src={base?.image}
                                    alt={base?.symbol}
                                    width={40}
                                    height={40}
                                />
                                <div className="exchange-group-input">
                                    <small className="exchange-confirm-value-label">From</small>
                                    <span className="exchange-confirm-value-item ">
                                        {fToken(baseValue)}
                                    </span>
                                </div>
                            </div>
                            <span className="exchange-confirm-currency " >{base.symbol} </span>
                        </Col>

                        <Col md={12} className="mb-4 d-flex justify-content-between">

                            <div className="exchange-group">
                                <img
                                    src={quote.image}
                                    alt={quote.symbol}
                                    width={40}
                                    height={40}
                                />
                                <div className="exchange-group-input">
                                    <small className="exchange-confirm-value-label">To</small>
                                    <span className="exchange-confirm-value-item ">
                                        {fToken(quoteValue)}
                                    </span>
                                </div>
                            </div>
                            <span className="exchange-confirm-currency" >{quote.symbol} </span>
                        </Col>
                    </Row>

                    <Row className="mb-5">
                        <Col md={12} className="d-flex align-items-center mb-3">
                            <small className="exchange-confirm-value-label">Slippage (50% Maximum)</small>
                        </Col>

                        <Col md={12} className="slippage-wrapper">
                            <Button className={classNames('slippage-btn', { 'active': slippageAuto === true })} onClick={() => updateSlippage('auto')}>Auto</Button>
                            <Button className={classNames('slippage-btn', { 'active': !slippageAuto && slippage === 0.5 })} onClick={() => updateSlippage(0.5)}>0.5 %</Button>
                            <Button className={classNames('slippage-btn', { 'active': !slippageAuto && slippage === 1 })} onClick={() => updateSlippage(1)}>1 %</Button>

                            <InputGroup className="slippage-input">
                                <AsyncItem loading={calculatingSlippage}>
                                    <Form.Control
                                        className="exchange-confirm-input ms-auto text-end"
                                        type="text"
                                        value={slippage}
                                        autoComplete="off"
                                        inputMode="decimal"
                                        onChange={e => updateSlippage(e.target.value)}
                                    />

                                </AsyncItem>
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col xs={6}  md={4} className="d-flex align-items-center">
                            <small className="exchange-confirm-value-label">Price</small>
                        </Col>

                        <Col  xs={6}  md={8} className="text-end">
                            <small className="exchange-confirm-value-label ">
                                {fToken(price)}  {quote.symbol}/{base.symbol}
                            </small>
                        </Col>
                    </Row>


                    <Row className="mb-2">
                        <Col xs={6} className="d-flex align-items-center">
                            <small className="exchange-confirm-value-label">Minimum Recevied</small>
                        </Col>

                        <Col xs={6} className="text-end">
                            <small className="exchange-confirm-value-label mt-0">
                                {fToken(minAmount)} {quote.symbol}
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`tooltip-Whatsapp`}>
                                            <strong>All taxes are included</strong>
                                        </Tooltip>
                                    }>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle info-icon" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                                        </svg>
                                    </span>
                                </OverlayTrigger>
                            </small>
                        </Col>
                    </Row>

                    {showTrack && <Row className="mb-2">
                        <Col xs={6} md={4} className="d-flex align-items-center">
                            <small className="exchange-confirm-value-label">Track Order</small>
                        </Col>

                        <Col xs={6}  md={8} className="text-end d-flex align-items-center justify-content-end">
                            <Form.Check
                                type="switch"
                                // defaultValue={track}
                                defaultChecked={track}
                                value={trackOrder}
                                onClick={() => setTrackOrder(!trackOrder)}
                                id="custom-switch"
                            />
                        </Col>
                    </Row>}

                    <div className='mt-4 text-center'>
                        <ActionButton />
                    </div>
                    {warning && <p className="text-center text-warning mt-2 me-auto ms-auto" >{warning}</p>}
                </Card.Body >
            </Card >
        </div >
    );
}

export default ExchangeConfirm;
