import BigNumber from 'bignumber.js';
import { parseUnits } from 'ethers/lib/utils';
import { Link } from 'gatsby';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useEffectOnce } from 'react-use';
import NetworkContext from '../../context/network-context';
import networkIcon from '../../images/chain-icon';
import { useNativeBalance } from '../../state/account';
import { useExchangeBalances, useExchangeConfiguration, useUpdateConfiguration } from '../../state/exchange';
import { fToken } from '../../utils/formatNumber';
import { getDefaultRouterAddress } from '../../utils/utils';
import { useApprove } from '../../web3/account';
import { Tokens } from '../../web3/contracts/contracts';
import { useBestPrice, useRelativeTokenPrice } from '../../web3/price-data';
import { useSwap } from '../../web3/router-utils';
import { getExchangeToken, getUsdCoin, useNativeCoin } from '../../web3/token-utils';
import { addChain, networks, TARGET_NETWORK, useTargetNetwork } from '../../web3/web3';
import CoinSelectInput from '../coin-select-input/coin-select-input';
import BalanceInfo from './balance-info';
import ExchangeConfirm from './exchange-confirm';

function Exchange() {
    const { account, connected, isCorrectNetwork, pendingTransaction, executeTransaction, showToast } = useContext(NetworkContext);
    const { base, quote } = useExchangeConfiguration();

    const [checked, setChecked] = useState(false);

    const [baseValue, setBaseValue] = useState(0);
    const [quoteValue, setQuoteValue] = useState(0);

    const [settingQuote, setSettingQuote] = useState(false);

    const { price, diff, refreshPrice, status: priceLoading, swapper, error } = useBestPrice(1, base, quote);
    const [baseUsdPrice] = useRelativeTokenPrice(getUsdCoin(base?.chainId), base, getDefaultRouterAddress(base?.chainId), base?.chainId);

    const [{ baseBalance }, refreshBalance] = useExchangeBalances();
    const [bnbBalance, refreshNativeBalance] = useNativeBalance();

    const [showConfirm, setShowConfirm] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState();
    const [isValid, setIsValid] = useState(false);

    const { swap, router } = useSwap(swapper, base, quote)
    const { approve, isApproved } = useApprove(base?.address, account, router?.ADDRESS, base?.chainId);
    const { setTargetNetwork } = useTargetNetwork();
    const nativeCoin = useNativeCoin(base?.chainId);
    const updateConfiguration = useUpdateConfiguration();

    const handleSwap = async () => {
        let tx;

        setModalStatus({
            balance: baseBalance,
            quoteValue,
            baseValue,
            quote,
            base,
            price,
            swapper,
            isApproved,
            showTrack: true,
            track: true,
            action: async (path, minAmount, trackOrder) => {
                tx = async () => await swap(path,
                    parseUnits(Number(baseValue).toFixed(base.decimals), base.decimals),
                    parseUnits(Number(minAmount).toFixed(quote.decimals), quote.decimals),
                    account, trackOrder);

                try {
                    await executeTransaction({
                        message: 'Executing Swap',
                        tx,
                        throwErr: true
                    });
                } catch (err) {
                    try {
                        console.log(err);
                        let _error = err.message;
                        const start = _error.indexOf("{");
                        const end = _error.lastIndexOf("}");

                        if (start && end) {
                            _error = JSON.parse(_error.substring(start, end + 1));
                        }

                        if (_error.message.includes("INSUFFICIENT_OUTPUT_AMOUNT")) {
                            showToast({ message: "Increase slippage", title: "Invalid configuration", delay: 2000 })
                            return;
                        }
                    } catch (error) {
                        showToast({ message: "Could not process transaction...", title: "Error", delay: 2000 })
                        console.log(error);

                    }
                }

                refreshBalance();
                refreshNativeBalance();
                refreshPrice();
                setShowConfirm(false);
            }
        });


        setShowConfirm(true);
    };

    const handleApprove = async () => {
        const tx = async () => await approve();

        await executeTransaction({
            message: 'Approving',
            tx
        });
    };

    //===== CALLBACKS ≠===

    const handleClose = useCallback(() => {
        if (pendingTransaction) {
            return;
        }
        setShowConfirm(false);
    }, [setShowConfirm, pendingTransaction]);

    const checkValid = useCallback(() => {
        // console.log("Network::", network)

        if (!base || !quote) {
            return true;
        }

        if (!isCorrectNetwork && connected) {
            // setErrorMessage(`Switch to $${networks[network].shortName}`);
            return false;
        }

        // console.log("base", baseValue);
        if (base.chainId !== quote.chainId) {
            setErrorMessage(`Tokens must be on the same chain.`);
            return false;
        }

        if (baseValue > 0 && (bnbBalance < 0.003 || (base.symbol === nativeCoin.symbol && bnbBalance - baseValue < 0.003))) {
            setErrorMessage(`Not enough balance left for gas.`);
            return false;
        }

        if (baseValue > baseBalance) {
            setErrorMessage(`Insufficent balance`);
            return false;
        }

        setErrorMessage(null);
        return !!baseValue && baseValue > 0;
    }, [baseValue, bnbBalance, setErrorMessage, baseBalance, quote, base, isCorrectNetwork]);

    const handleCoinSelect = useCallback((side) => (coin) => {
        if (side === "quote") {
            if (base.address === coin.address) {
                updateConfiguration(quote, base);
            } else {
                updateConfiguration(base, coin);
            }
        } else {
            if (quote.address === coin.address) {
                updateConfiguration(quote, base);
            } else {
                updateConfiguration(coin, base);
            }
            setTargetNetwork(coin.chainId);
        }
        console.log("Handling select",)
        updateInput('baseInput', 'quoteInput')(baseValue);

    }, [updateConfiguration, quote, base]);

    const updateInput = useCallback((source, target) => (value) => {
        let _price = source === 'baseInput' ? price : 1 / price;
        const relativeValue = new BigNumber(value * _price).toFixed(12, 1);

        if (target === 'baseInput') {
            setSettingQuote(true);
            setQuoteValue(+value);
            setBaseValue(+relativeValue);
            setSettingQuote(false);
        }

        // console.log("Updating inputs", relativeValue, price, value)
        // console.log("SETTING", target, settingQuote)
        if (target === 'quoteInput' && !settingQuote) {
            setQuoteValue(+relativeValue)
            setBaseValue(+value);
        }

        // console.log("Updating inputs::Price", price, value, relativeValue)
    }, [setBaseValue, setSettingQuote, setQuoteValue, settingQuote, price]);

    const setMax = useCallback(() => {
        setBaseValue(baseBalance);
        updateInput('baseInput', 'quoteInput')(baseBalance);
    }, [updateInput, setBaseValue, baseBalance]);

    //===== CALLBACKS ≠===

    const toggleMode = () => {
        updateConfiguration(quote, base);
    }

    //===== EFFECTS ≠===

    useEffectOnce(async () => {
        const _base = await getExchangeToken(56, 'BNB');
        const _quote = await getExchangeToken(56, 'HPAY');
        updateConfiguration(_base, _quote);
    })

    useEffect(() => {
        setIsValid(checkValid());
    }, [baseValue, base, quote, quoteValue])

    useEffect(() => {
        if (updateInput) {
            updateInput('baseInput', 'quoteInput')(baseValue);
        }
    }, [price, updateInput]);

    useEffect(() => {
        if (!account || !connected) {
            return;
        }
        refreshBalance();
        refreshNativeBalance();
    }, [account, base, connected, refreshBalance, refreshNativeBalance]);

    //===== EFFECTS ≠===

    const ActionButton = () => {
        if (!isCorrectNetwork && connected) {
            const networkName = networks[+TARGET_NETWORK].shortName;
            return (<Button
                disabled={!!pendingTransaction}
                onClick={() => addChain(+TARGET_NETWORK)} className="mb-2 d-flex justify-content-center align-items-center text-center w-100" >
                Switch to {networkName}
                <img style={{ width: '20px', height: '20px', marginLeft: '0.5rem' }}
                    src={networkIcon[networkName]} alt="icon" />
            </Button>);
        }
        if (isApproved) {
            return (<Button
                disabled={!connected || !isValid || !isCorrectNetwork || !!pendingTransaction || !checked}
                onClick={handleSwap} className="mb-2 w-100" >Swap</Button>);
        } else {
            return (<Button disabled={!connected || !isCorrectNetwork || !isValid || !!pendingTransaction}
                onClick={handleApprove} className="mb-2 w-100" >Approve</Button>);
        }
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={12}>
                            <BalanceInfo
                                usdBalance={new BigNumber(baseBalance).times(baseUsdPrice).toFixed(2, 1)}
                                tokenBalance={baseBalance} symbol={base?.symbol}></BalanceInfo>
                            <hr className='separator' />
                            <Form>
                                <Row className="justify-content-center no-gutters">
                                    <Form.Group as={Col} md="12" className="input-control" controlId="validationFormik01">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label className="input-label">You Pay</Form.Label>
                                            <Form.Label className="input-label">
                                                {(baseValue > 0 && baseUsdPrice > 0) && '~$' + new BigNumber(baseValue).times(baseUsdPrice).toFixed(2, 1)}
                                            </Form.Label>
                                        </div>
                                        <CoinSelectInput
                                            defaultSelected={Tokens.BNB}
                                            value={baseValue}
                                            selected={base}
                                            showMax={true}
                                            handleMax={setMax}
                                            onInput={updateInput('baseInput', 'quoteInput')}
                                            onSelect={handleCoinSelect('base')}>
                                        </CoinSelectInput>

                                    </Form.Group>
                                    <Button className="btn-swipe  d-flex w-100  align-items-center
                                        px-3
                                        justify-content-between"
                                        disabled={pendingTransaction}
                                        onClick={toggleMode}>

                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2.5em" height="2.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><g transform="rotate(90 256 256)"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="m304 48l112 112l-112 112m94.87-112H96m112 304L96 352l112-112m-94 112h302"></path></g></svg>

                                        <small className="price-diff-label--small-thin text-end">

                                            {!priceLoading.value || priceLoading.loading && <p className="card-text placeholder-glow " style={{ minWidth: 150 }}>
                                                <span className="placeholder col-12" style={{ height: 35 }}></span>
                                            </p>}

                                            {error && !priceLoading.value && !priceLoading.loading &&
                                                <>
                                                    Inssuficient Liquidity
                                                </>
                                            }

                                            {!error && priceLoading.value && !priceLoading.loading &&
                                                <>

                                                    <p className='p-0' style={{ fontSize: '0.8rem' }}>
                                                        {
                                                            diff > 0 ? <>
                                                                Better by <span>+{diff}%</span>
                                                            </> : "Arbitrage not available"
                                                        }
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id={`tooltip-Whatsapp`}>
                                                                    <strong>We try to give you the best price out of a multitude of dexes!</strong>
                                                                </Tooltip>
                                                            }>
                                                            <span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle info-icon" viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                                                                </svg>
                                                            </span>
                                                        </OverlayTrigger>

                                                    </p>

                                                    {fToken(+price)}  {quote?.symbol}/{base?.symbol}
                                                </>
                                            }
                                        </small>

                                    </Button>
                                    <Form.Group as={Col} md="12" className="input-control" controlId="validationFormik01">

                                        <CoinSelectInput
                                            defaultSelected={Tokens.HPAY}
                                            targetChainId={base?.chainId}
                                            value={quoteValue}
                                            selected={quote}
                                            onInput={updateInput('quoteInput', 'baseInput')}
                                            onSelect={handleCoinSelect('quote')}>
                                        </CoinSelectInput>

                                    </Form.Group>
                                    <Form.Group as={Col} md="12" className="mt-4 mb-0 d-flex flex-column align-items-center ">
                                        <div className="mb-3 text-sm col-12 ">
                                            <div className="custom-control custom-checkbox text-left d-flex">
                                                <input type="checkbox" onClick={() => setChecked(!checked)} className="custom-control-input align-self-center me-2" id="customCheck1" />
                                                <label className="custom-control-label" htmlFor="customCheck1"><small>I have read and agree with HedgePay <Link to="/terms">Terms of Service</Link>. I have done my research to make sure I am legally able to purchase this token in my country of residence.</small></label>
                                            </div>
                                        </div>
                                        <Col xs={12}>
                                            <ActionButton />
                                            {errorMessage && <p className="mb-0 text-center">{errorMessage}</p>}
                                        </Col>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Col>

                    </Row>
                </Card.Body>
            </Card >

            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showConfirm} onHide={handleClose} >

                <Modal.Body>
                    <ExchangeConfirm  {...modalStatus} cancel={handleClose}></ExchangeConfirm>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default Exchange;
