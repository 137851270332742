import { parseUnits } from 'ethers/lib/utils';
import React, { useCallback, useContext, useState } from 'react';
import { Button, Col, Dropdown, Modal, Row } from 'react-bootstrap';
import { Paginated } from '../../containers/paginated';
import NetworkContext from '../../context/network-context';
import networkIcon from '../../images/chain-icon';
import { useAccountOrders, useAddTrade, useExchangeBalances, useRemoveOrder } from '../../state/exchange';
import { fToken } from '../../utils/formatNumber';
import { fetchTokenBalance, useApproveCheck, useApproveRequest } from '../../web3/account';
import { useOrdersActions } from '../../web3/orders';
import { fetchBestPrice } from '../../web3/price-data';
import { getRouter, swap } from '../../web3/router-utils';
import { networks } from '../../web3/web3';
import ExchangeConfirm from './exchange-confirm';
import NoData from '../../images/inline/nodata.inline.svg';

import Pnl from "./pnl";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }} className="dropdown-dots"></div>
));


const ChainCell = ({ chainId }) => (<div className="d-flex align-items-center chain-cell">
    <img style={{ width: '15px', height: '15px', marginRight: '0.75rem' }}
        src={networkIcon[networks[+chainId].shortName]} alt="icon" />
    <span>{networks[+chainId].shortName}</span>

</div>)

const PairCell = ({ from, to }) => (<div className="d-flex align-items-center">
    <span style={{ wordBreak: 'break-word' }}>{from?.symbol} / {to?.symbol}</span>
</div>)

function Orders() {
    const { account, executeTransaction, pendingTransaction, network, showToast } = useContext(NetworkContext);
    const { orders, pageCount } = useAccountOrders()
    const removeOrder = useRemoveOrder();
    const addTrade = useAddTrade();

    const [page, setPage] = useState(0);
    const [showConfirm, setShowConfirm] = useState(false);
    const [openingModal, setOpeningModal] = useState(false);

    const [modalStatus, setModalStatus] = useState(null);

    const checkApproved = useApproveCheck(network);
    const requestApprove = useApproveRequest(network);
    const [, refreshBalances] = useExchangeBalances();


    const handleClose = useCallback(() => {
        if (pendingTransaction) {
            return;
        }
        setShowConfirm(false);
    }, [setShowConfirm, pendingTransaction]);


    const removePosition = useCallback(async (id) => {
        await removeOrder(id)
    }, [removeOrder]);

    const closeOrder = useCallback(async (order) => {
        setOpeningModal(true);
        const base = order.to;
        const baseValue = +order.amountOut;
        const quote = order.from;
        const quoteValue = order.amountIn;

        const { price, router: swapper } = await fetchBestPrice('1', order.to, order.from);
        const router = await getRouter([base, quote], swapper);

        const swapFn = swap(router, async ({ order: trade }) => {
            await addTrade(trade);
            await removeOrder(order.id);
        });

        const check = checkApproved(account, router.ADDRESS, base.address);
        let isApproved = await check('' + baseValue);
        const _approve = requestApprove(account, router.ADDRESS, base.address);
        const baseBalance = await fetchTokenBalance(base.address, account, network);

        const handleApprove = async () => {
            await executeTransaction({
                message: 'Approving',
                tx: async () => {
                    await _approve();
                }
            });
        }

        const handleUntrack = async () => {
            removeOrder(order.id);
            setShowConfirm(false);
        }

        console.log(Math.abs(+baseBalance - +baseValue), baseBalance, baseValue);
        setModalStatus({
            balance: +baseBalance,
            quoteValue,
            baseValue: Math.abs(+baseBalance - +baseValue) < 1e-8 ? baseBalance : baseValue,
            quote,
            base,
            price,
            swapper,
            isApproved,
            handleApprove,
            handleUntrack,
            action: async (path, minAmount) => {
                let tx = async () => await swapFn(path,
                    parseUnits(Number(baseValue).toFixed(base.decimals), base.decimals),
                    parseUnits(Number(minAmount).toFixed(quote.decimals), quote.decimals),
                    account);

                try {
                    await executeTransaction({
                        message: 'Executing Swap',
                        tx,
                        throwErr: true
                    });
                    setShowConfirm(false);
                    refreshBalances();
                } catch (err) {
                    try {
                        console.log(err);
                        let _error = err.message;
                        const start = _error.indexOf("{");
                        const end = _error.lastIndexOf("}");

                        if (start && end) {
                            _error = JSON.parse(_error.substring(start, end + 1));
                        }

                        if (_error.message.includes("INSUFFICIENT_OUTPUT_AMOUNT")) {
                            showToast({ message: "Increase slippage", title: "Invalid configuration", delay: 2000 })
                            return;
                        }
                    } catch (error) {
                        showToast({ message: "Could not process transaction...", title: "Error", delay: 2000 })
                        console.log(error);
                    }
                }
            }
        });
        setOpeningModal(false);
        setShowConfirm(true);
    }, [setShowConfirm, setOpeningModal, executeTransaction, checkApproved, requestApprove, refreshBalances])

    return <>
        <Col md={12}>
            <table className="table exchange-history">
                <thead>
                    <tr>
                        <th scope="col">Chain</th>
                        <th scope="col">Pair</th>
                        <th className="d-none d-sm-table-cell"scope="col">Spent</th>
                        <th className="d-none d-sm-table-cell"scope="col">Received</th>
                        <th scope="col">PNL</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {orders && orders?.map(item => <tr key={item.id}>
                        <td scope="row">
                            <ChainCell chainId={item.chain} />
                        </td>
                        <td>
                            <PairCell from={item.from} to={item.to} />
                        </td>
                        <td className="d-none d-sm-table-cell" >{fToken(item.amountIn)} {item?.from?.symbol}</td>
                        <td className="d-none d-sm-table-cell" >{fToken(item.amountOut)} {item?.to?.symbol}</td>
                        <td><Pnl tokenA={item.to} tokenB={item.from} startPrice={item.price} /></td>
                        <td className='d-flex'>
                            <Button disabled={openingModal} onClick={() => closeOrder(item)} className="close-trade">Close</Button>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-custom-components" as={CustomToggle}>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => removePosition(item.id)}>Stop Tracking</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>

                    </tr>)
                    }
                </tbody>
            </table>
            {(!orders || orders.length === 0) &&
                <Row className="w-100 m-auto">
                    <NoData className="my-3 mx-auto" />
                </Row>
            }
        </Col>

        <Col md={12} className="d-flex justify-content-center flex-column align-items-center">
            <Paginated nextLabel=">" onPageChange={({ selected }) => setPage(selected)} pageCount={pageCount} previousLabel="<" renderOnZeroPageCount={null} />
        </Col>

        <Modal
            className="stake-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showConfirm}
            onHide={handleClose} >

            <Modal.Body>
                <ExchangeConfirm {...modalStatus} cancel={handleClose}></ExchangeConfirm>
            </Modal.Body>

        </Modal>
    </>
}


export default Orders;