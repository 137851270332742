
import AutoNumeric from 'autonumeric';
import { ethers } from 'ethers';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Badge, Col, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { useDebounce } from 'react-use';
import NetworkContext from '../../context/network-context';
import networkIcon from '../../images/chain-icon';
import { fetchPairs, fetchUserToken, removeUserToken } from '../../web3/token-utils';
import { networks } from '../../web3/web3';
import AsyncItem from '../misc/AsyncItem';

const CoinItemSelect = ({ image, symbol, onSelect, onRemove, address, chainId, unverified }) => (
    <div role="button" onKeyPress={onSelect} tabIndex={0} onClick={() => onSelect()} className="modal-row-item coin-item-dropdown-select align-items-center">
        <div>
            <h5>{symbol}
                <img style={{ width: '15px', height: '15px', marginLeft: '0.75rem' }}
                    src={networkIcon[networks[+chainId].shortName]} alt="icon" />
                {unverified && <Badge pill className="ms-2 badge-primary border-0" >
                    <span className="text-primary-gradient ">
                        Unverified
                    </span>
                </Badge>}
            </h5>
            <small className='generic-label--small-thin'>
                {networks[chainId].shortName} : {address.slice(0, 5)}...{address.slice(30)}

            </small>
        </div>
        <div>
            {unverified && <Badge pill
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRemove();
                }}
                className="me-2 badge-primary clickable" >
                <span className="text-primary-gradient ">
                    Remove
                </span>
            </Badge>}
            <img
                src={image}
                alt={symbol}
                width={45}
                height={45}
            />
        </div>
    </div>);


function CoinSelectInput({ onSelect, targetChainId, defaultSelected, onInput, selected, value, showMax, handleMax }) {
    const { pendingTransaction } = useContext(NetworkContext);
    const [, setSelectedValue] = useState(null);
    const [numericHandle, setNumericHandle] = useState(null);
    const [show, setShow] = useState(false);
    const [coins, setCoins] = useState(false);
    const [search, setSearch] = useState();
    const [fetching, setFetching] = useState();

    const input = useRef(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (!numericHandle) {
            return;
        }
        numericHandle.set(value);
    }, [value, numericHandle])


    const handleUserTokenRemove = async (token) => {
        removeUserToken(token);
        const _search = ethers.utils.isAddress(search) ? "" : search;
        await loadCoins(search);
    }

    const loadCoins = useCallback(
        (search) => fetchPairs(targetChainId, search, [defaultSelected.address]).then(data => {
            setCoins(data);
            if (ethers.utils.isAddress(search)) {
                setFetching(true);
                fetchUserToken(search).then(_data => {
                    setCoins([...data, ..._data]);
                    setFetching(false);
                });
            }
        }, []));

    useDebounce(() => {
        loadCoins(search)
    }, 100, [search])

    useEffect(() => {
        fetchPairs(targetChainId, null, [defaultSelected.address]).then(setCoins)
    }, [setCoins, targetChainId, defaultSelected])

    useEffect(() => {
        if (!coins || !selected) {
            return;
        }
        let _selected = coins[0];
        if (defaultSelected) {
            _selected = defaultSelected;
        }
        setSelectedValue(_selected);
    }, [defaultSelected, coins, selected]);


    useEffect(() => {
        if (!selected || numericHandle !== null) {
            return;
        }
        const handle = new AutoNumeric(input.current, 0, {
            decimalPlaces: 8,
            minimumValue: 0,
            maximumValue: Number.MAX_SAFE_INTEGER,
            showWarnings: false,
            allowDecimalPadding: false,
            emptyInputBehavior: 0,
            decimalCharacterAlternative: ','
        });
        setNumericHandle(handle)
    }, [input, selected]);

    useEffect(() => {
        if (!selected) {
            return;
        }
        setSelectedValue(selected);
        fetchPairs(targetChainId, null, [selected.address]).then(setCoins)
    }, [selected, targetChainId])

    const handleSelect = (coin) => {
        if (pendingTransaction) {
            return;
        }
        setSelectedValue(coin);
        setShow(false);
        fetchPairs(targetChainId, null, [coin.address]).then(setCoins)

        if (onSelect) {
            onSelect(coin);
        }
    };

    return (<>
        <AsyncItem loading={!!!selected} height={45}>
            <div className='coin-select-input-wrapper'>
                <div className='coin-select-input'>
                    <img src={selected?.image} alt='token' className='me-1 symbol-icon' />

                    <input type="text"
                        autoComplete="off"
                        inputMode="decimal"
                        name="input"
                        onChange={() => { onInput(numericHandle.getNumber()) }}
                        ref={input} />


                    <div className='input-symbol d-flex align-items-center text-primary-gradient'>
                        {showMax && <div
                            role="button" onKeyPress={handleMax} onClick={handleMax} tabIndex={0}
                            className='max me-1 d-flex align-items-center'>
                            MAX
                        </div>}
                        <div
                            className='input-symbol d-flex align-items-center'
                            role="button" onKeyPress={handleShow} onClick={handleShow} tabIndex={0}
                        >

                            {
                                selected &&
                                <img style={{ width: '15px', height: '15px', marginRight: '0.35rem' }}
                                    src={networkIcon[networks[selected?.chainId].shortName]} alt="icon" />
                            }
                            <span className='me-2'>{selected?.symbol}</span>
                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L13 1" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path><path d="M1 1L7 7L13 1" stroke="url(#paint0_linear_521_1583)" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path><defs><linearGradient id="paint0_linear_521_1583" x1="0.557046" y1="-6.07143" x2="19.2764" y2="2.60286" gradientUnits="userSpaceOnUse"><stop stopColor="#FFE986" stopOpacity="0.88"></stop><stop offset="0.670219" stopColor="#BC7E20"></stop><stop offset="0.670319" stopColor="#BC7D1F"></stop><stop offset="1" stopColor="#A74D01"></stop></linearGradient></defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

        </AsyncItem>
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered show={show} onHide={handleClose} >
            <Modal.Body style={{ height: 415 }}>
                <Col md={12} className="mb-md-5 mx-auto">
                    <InputGroup className='form-hpay-input-group'>
                        <FormControl placeholder="Search here..."
                            onChange={(e) => { setSearch(e.target.value) }}
                            className='form-hpay' />
                    </InputGroup>
                </Col>
                <Col md={12} style={{ overflowX: 'scroll', maxHeight: 320 }}>
                    <div>
                        {
                            coins && coins.map((coin, index) => <CoinItemSelect onRemove={() => handleUserTokenRemove(coin)}  {...coin} onSelect={() => handleSelect(coin)} key={index} />)
                        }
                    </div>
                    {(!coins || coins.length === 0) && !fetching && "Token not found or it has low liquidity... Trying searching by contract address..."}
                    {fetching && "Searching..."}
                </Col>
            </Modal.Body>

        </Modal>
    </>
    );
}

export default CoinSelectInput;
