import React from 'react';
import CountUp from 'react-countup';


function BalanceInfo({ usdBalance, tokenBalance, symbol }) {

    return <>
        <p className='generic-label'>Your balance</p>
        <h4 className='my-2 h4-display'>
            <CountUp duration={0.4} isCounting end={tokenBalance || 0} decimals={tokenBalance > 1e4 ? 4 : 8} preserveValue={false} separator=',' /> {symbol}
        </h4>
        <p className='generic-label generic-label--small-thin mb-4'>
            <img src='/icons/USD.svg' alt='USD' className='me-1 symbol-icon' />
            {usdBalance} USD
        </p>
    </>

}

export default BalanceInfo;