
import HPAYROUTERS from 'hpay/content/hpay-routers.json';
import HPaySwapper from 'hpay/contracts/ASwapper.json';
import UniswapV2 from 'hpay/contracts/UniswapV2.json';

import { ethers } from 'ethers';
import { RUNTIME_ENV } from "gatsby-env-variables";
import { useCallback, useEffect, useState } from 'react';
import { useAddOrder, useAddTrade } from '../state/exchange';
import { fetchTokenBalance } from './account';
import { ContractFactoryHof } from './contracts/contract.factory';
import { getNativeCoin } from './token-utils';

export const getRouter = async (path, swapper) => {
    if (!path || !swapper) {
        return;
    }

    const inToken = path[0];
    const outToken = path[path.length - 1];

    if (!inToken || !outToken) {
        return;
    }

    if (path[0].chainId !== path[path.length - 1].chainId) {
        return;
    }

    const isCore = inToken.core && outToken.core;
    if (isCore) {
        console.log("Using core router");
        return HpayRouter(swapper);
    }

    // console.log(inToken, outToken)

    const isProxy = (inToken.proxy || inToken.core) && (outToken.proxy || outToken.core)
    if (isProxy) {
        console.log("Using proxy router");
        return HPayProxyRouter(swapper);
    }

    console.log("Using fallback router", swapper);
    return UniswapRouter(swapper);
}

export const swap = (router, callback) => async (path, amount, minAmount, account, trackOrder) => {
    if (!router) {
        return;
    }

    // console.log("\n === CONFIGURATION === \n");
    // console.log(`\n === AMOUNT ${amount} :: MIN ${minAmount} === \n`);
    // console.log(`\n === ROUTER ${router.ADDRESS}=== \n`);
    // console.log(`\n === Token A ${path[0].address}=== \n`);
    // console.log(`\n === Token B ${path[path.length - 1].address}=== \n`);

    const method = router.getRouterMethod(path, amount, minAmount);

    // console.log("Executing trade", path, amount, minAmount, account)
    const { result, order } = await method(account);
    order.account = ethers.utils.getAddress(account);

    // console.log("Executing trade", path, amount, minAmount, account)

    callback({ result, order, trackOrder });

    return result;
};

export const useSwap = (swapper, base, quote) => {
    const addOrder = useAddOrder();
    const addTrade = useAddTrade();

    const router = useRouter(swapper, base, quote)

    const swapFn = useCallback(swap(router, ({ order, trackOrder }) => {
        if (trackOrder) {
            addOrder(order);
        }
        addTrade(order);
    }), [router, addOrder]);

    return { swap: swapFn, router };
}

export const useRouter = (swapper, base, quote) => {
    const [router, setRouter] = useState();

    useEffect(() => {
        getRouter([base, quote], swapper).then((_router) => {
            setRouter(_router);
        });
    }, [swapper, base, quote]);

    return router;
}


const getRouterMethod = ({ nativeCoin, fromEthToTokens, fromTokenToEth, fromTokenToTokens }) => (path, amount, minAmount) => {
    if (path[0].address === nativeCoin.address) {
        const execute = fromEthToTokens(path, amount, minAmount);
        return async (account) => {
            const tx = await execute(account)
            const { inputToken, outputToken, amountOut, amountIn } = await calculatedAmounts(path, account, tx);
            const _amountIn = amountIn - (tx.gasUsed * tx.effectiveGasPrice) / 1e18;
            const order = getOrder(inputToken, tx.transactionHash, outputToken, amountOut, _amountIn)
            return { result: tx, order }
        }
    }

    if (path[path.length - 1].address === nativeCoin.address) {
        const execute = fromTokenToEth(path, amount, minAmount);

        return async (account) => {
            const tx = await execute(account)
            const { inputToken, outputToken, amountOut, amountIn } = await calculatedAmounts(path, account, tx);
            const _amountOut = amountOut + (tx.gasUsed * tx.effectiveGasPrice) / 1e18;
            const order = getOrder(inputToken, tx.transactionHash, outputToken, _amountOut, amountIn)
            return { result: tx, order }
        }

    }
    const execute = fromTokenToTokens(path, amount, minAmount);
    return async (account) => {
        const tx = await execute(account)
        const { inputToken, outputToken, amountOut, amountIn } = await calculatedAmounts(path, account, tx);
        const order = getOrder(inputToken, tx.transactionHash, outputToken, amountOut, amountIn);
        return { result: tx, order }
    }
}


const UniswapRouter = async (swapper) => {
    const FEE = 0;
    const router = await ContractFactoryHof(window.web3).create(UniswapV2.abi, swapper.router)
    const nativeCoin = getNativeCoin(swapper.chainId);

    const fromEthToTokens = (path, amount, minAmount) => async (account) => {

        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: amount
        };
        const configuration = router.methods
            .swapExactETHForTokensSupportingFeeOnTransferTokens(
                minAmount,
                path.map(item => item.address),
                account,
                Date.now() + 5000
            )

        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    const fromTokenToEth = (path, amount, minAmount) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };
        const configuration = router.methods
            .swapExactTokensForETHSupportingFeeOnTransferTokens(
                amount,
                minAmount,
                path.map(item => item.address),
                account,
                Date.now() + 5000
            )
        await configuration.estimateGas(args);
        return configuration.send(args);

    };

    const fromTokenToTokens = (path, amount, minAmount) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const configuration = router.methods
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(
                amount,
                minAmount,
                path.map(item => item.address),
                account,
                Date.now() + 5000
            )
        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    return {
        getRouterMethod: getRouterMethod({
            nativeCoin, fromEthToTokens, fromTokenToEth, fromTokenToTokens,
            swapperAddress: swapper.router,
        }),
        FEE, ADDRESS: swapper.router
    }
}

const HpayBaseRouter = async (swapper, abi, address) => {
    const FEE = 0.1;
    const router = await ContractFactoryHof(window.web3).create(abi, address)
    const nativeCoin = getNativeCoin(swapper.chainId);

    const fromEthToTokens = (path, amount, minAmount) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: amount
        };

        const configuration = router.methods.swapFromNative(
            swapper.router,
            path.map(item => item.address),
            minAmount
        )

        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    const fromTokenToEth = (path, amount, minAmount) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const configuration = router.methods.swapToNative(
            swapper.router,
            path.map(item => item.address),
            amount,
            minAmount
        );

        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    const fromTokenToTokens = (path, amount, minAmount) => async (account) => {
        const gasPrice = await window.web3.eth.getGasPrice();
        let args = {
            from: account,
            gasPrice: gasPrice,
            value: 0
        };

        const configuration = router.methods.swap(
            swapper.router,
            path.map(item => item.address),
            amount, minAmount
        );
        await configuration.estimateGas(args);
        return configuration.send(args);
    };

    return {
        getRouterMethod: getRouterMethod({
            nativeCoin, fromEthToTokens, fromTokenToEth, fromTokenToTokens,
            swapperAddress: address,
        }),

        FEE, ADDRESS: address
    }
}

const HpayRouter = async (swapper) => {
    console.log("ENVIRONMENT", RUNTIME_ENV)
    if (RUNTIME_ENV === 'mainnet' && HPAYROUTERS[swapper.chainId].SIMPLE !== '0x76da5668E5210094002ABc6af6f0102d08658908') {
        throw "Invalid router";
    }
    return HpayBaseRouter(swapper, HPaySwapper.abi, HPAYROUTERS[swapper.chainId].SIMPLE)
}

const HPayProxyRouter = async (swapper) => {
    console.log("ENVIRONMENT", RUNTIME_ENV)
    if (RUNTIME_ENV === 'mainnet' && HPAYROUTERS[swapper.chainId].PROXY !== '0x25363215B9b463E329c44FFb830D24fe1BaeE88e') {
        throw "Invalid router";
    }
    return HpayBaseRouter(swapper, HPaySwapper.abi, HPAYROUTERS[swapper.chainId].PROXY)
}

async function calculatedAmounts(path, account, tx) {
    const inputToken = path[0];
    const outputToken = path[path.length - 1];

    const { outBefore, ouAfter, inBefore, inAfter } = await balanceInBetweenBlocks(inputToken, account, tx, outputToken);
    const amountOut = ouAfter - outBefore;
    let amountIn = inBefore - inAfter;
    return { inputToken, outputToken, amountOut, amountIn };
}

async function balanceInBetweenBlocks(inputToken, account, tx, outputToken) {
    const inBefore = await fetchTokenBalance(inputToken.address, account, inputToken.chainId, tx.blockNumber - 1);
    const inAfter = await fetchTokenBalance(inputToken.address, account, inputToken.chainId, tx.blockNumber);

    const outBefore = await fetchTokenBalance(outputToken.address, account, outputToken.chainId, tx.blockNumber - 1);
    const ouAfter = await fetchTokenBalance(outputToken.address, account, outputToken.chainId, tx.blockNumber);
    return { outBefore, ouAfter, inBefore, inAfter };
}

function getOrder(inputToken, txid, outputToken, amountOut, _amountIn) {
    return {
        chain: inputToken.chainId,
        txid,
        from: inputToken,
        to: outputToken,
        price: amountOut / _amountIn,
        amountOut,
        amountIn: _amountIn,
        date: Date.now()
    };
}
