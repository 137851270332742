import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import AsyncItem from '../misc/AsyncItem';
import { useInterval } from 'react-use';
import ChainIcon from '../../images/chain-icon.png';
import { useExchangeConfiguration } from '../../state/exchange';
import { getDefaultRouterAddress } from '../../utils/utils';
import { useTokenContract } from '../../web3/contracts/contracts';
import { useRelativeTokenPrice } from '../../web3/price-data';
import { getNativeCoin, useUsdCoin } from '../../web3/token-utils';

function PresaleStats() {
    const [marketCap, setMarketCap] = useState(0);
    const [token, setToken] = useState();


    const { base, quote } = useExchangeConfiguration();
    const usdCoin = useUsdCoin(base?.chainId)

    const [price, refreshPrice] = useRelativeTokenPrice(usdCoin, token, getDefaultRouterAddress(token?.chainId), token?.chainId);
    const { supply } = useTokenContract(token?.address);

    useEffect(() => {
        // console.log("Setting token", base, quote, usdCoin)
        if (!base || !quote || !usdCoin) {
            return;
        }

        if (quote.chainId === usdCoin.chainId && quote.address === usdCoin.address) {
            setToken(base);
            return;
        }

        const nativeCoin = getNativeCoin(base.chainId);
        if (quote.chainId === nativeCoin.chainId && quote.address === nativeCoin.address) {
            setToken(base);
            return;
        }

        setToken(quote);
    }, [base, quote, usdCoin])

    useEffect(() => {
        if (refreshPrice) {
            // console.log("New token set", token);
            refreshPrice();
        }
    }, [token]);


    useEffect(() => {
        setMarketCap(+(price * supply).toFixed(2));
    }, [price, setMarketCap, supply]);

    useInterval(() => {
        if (refreshPrice) {
            refreshPrice();
        }
    }, 10000);

    return (
        <Row className='m-0'>
            <Card className='p-0'>
                <Card.Body>
                    <Row className="token-info">
                        <Col md={12} className="mb-3 d-flex justify-content-between flex-wrap">
                            <Col xs={12} md={8}>
                                <div className="eco-group d-flex mb-2" style={{ marginLeft: '-3px' }}>
                                    <AsyncItem loading={!!!token} height={30}>
                                        <img src={token?.image}
                                            width={25}
                                            height={25}
                                            alt="HedgePay Finance Ticker" />
                                        <h5 className='ms-2 mb-0'>{token?.name} <span className='faded'>{token?.symbol}</span></h5>
                                    </AsyncItem>
                                </div>
                                <div className='d-flex align-items-center'>
                                        <p className="me-2 mb-0 d-none d-md-block">
                                            Contract:
                                        </p>
                                        <p className="me-2 address" style={{ wordBreak: 'break-all' }}>
                                            <AsyncItem loading={!!!token} height={10} minWidth={250}>
                                                <span>
                                                    {token?.address}
                                                </span>
                                            </AsyncItem>
                                        </p>
                                </div>
                            </Col>

                            <Col xs={12} md={4} className="mb-3 text-md-end">
                                <div className="eco-group">
                                    <AsyncItem loading={!!!token} minWidth={80}>
                                        <p className="label-text mb-0 mb-md-2">{token?.name} Price</p>
                                        <h4 className='h4-display m-0'>$
                                            <CountUp isCounting end={price || 0} decimals={8} preserveValue={true} separator=',' />
                                        </h4>
                                    </AsyncItem>
                                </div>
                            </Col>
                        </Col>

                        <Col md={12}>
                            <div className="justify-content-between row">
                                <Col md={4} >
                                    <div className="token-info-stats">
                                        <p className="label-text">Chain</p>
                                        <img src={ChainIcon} alt="icon" /> BSC
                                    </div>
                                </Col>

                                <Col md={4} >
                                    <div className="token-info-stats">
                                        <p className="label-text">Circulating Supply</p>
                                        <AsyncItem loading={!!!token} height={10}>
                                            <CountUp isCounting end={supply || 0} decimals={0} preserveValue={true} separator=',' /> {token?.symbol}
                                        </AsyncItem>

                                    </div>
                                </Col>

                                <Col md={4} >
                                    <div className="token-info-stats text-start text-md-end">
                                        <p className="label-text">MarketCap</p>
                                        <AsyncItem loading={!!!marketCap} height={10}>
                                            $ <CountUp isCounting end={marketCap || 0} decimals={2} preserveValue={true} separator=',' />
                                        </AsyncItem>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>
    );
}

export default PresaleStats;
