import React from 'react';

const AsyncItem = ({ loading, children, minWidth, height }) => {
    return <>
        {loading ? <span className="card-text placeholder-glow " style={{ minWidth: minWidth || 150 }}>
            <span className="placeholder col-12" style={{ height: height || 35 }}></span>
        </span> : <>{ children }</>
        }
    </>
}


export default AsyncItem;
